import { cloneElement } from 'react';
import styled from 'styled-components';
import { Theme, Card as MuiCard, CardContent, Typography } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import { SourcePreview } from '/shared/FilePreview';
import { LabelThemed } from '/shared/LabelThemed';

export const NotFoundTextWrapper = styled(Typography)`
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing(4)};
`;

export const Card = styled(MuiCard)`
  position: relative;
  width: 220px;
  box-shadow: none;
  border: 1px solid ${(props) => props.theme.palette.divider};
`;

export const Content = styled(CardContent)`
  padding: ${(props) => props.theme.spacing()}px;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing()}px;
  }
`;

export const Title = styled(Typography)`
  word-break: break-word;
`;

export const AppImage = styled(SourcePreview)`
  max-height: 300px;
`;

export const SelectedIcon = styled(CheckCircleIcon)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  color: ${(props) => props.theme.palette.success.main};
`;

export const SecondaryIcon = styled(({ component, ...props }) => cloneElement(component, props))`
  color: ${(props: { theme: Theme }) => props.theme.palette.text.secondary};
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(2)};
  padding-top: ${(props) => props.theme.spacing(4)};
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
`;

export const FingerprintIdLabel = styled(LabelThemed)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
