import { red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { Theme } from '@mui/material';
import { Spacing } from '@mui/system';

export const useLoginStyles = makeStyles((theme: Theme) => {
  const themeSpacing: Spacing = theme.spacing.bind(theme);

  return {
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      margin: themeSpacing(1),
    },
    form: {
      marginTop: themeSpacing(1),
      width: '100%',
    },
    formControl: {
      marginTop: themeSpacing(2),
      marginBottom: themeSpacing(1),
    },
    submitWrapper: {
      position: 'relative',
    },
    submitLoader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    main: {
      display: 'block',
      marginLeft: themeSpacing(3),
      marginRight: themeSpacing(3),
      [theme.breakpoints.up(400 + parseInt(themeSpacing(3)) * 2)]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 400,
      },
      width: 'auto',
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: themeSpacing(8),
      padding: `${themeSpacing(2)} ${themeSpacing(3)} ${themeSpacing(3)}`,
    },
    root: {
      textAlign: 'center',
    },
    error: {
      color: red[500],
    },
    submit: {
      marginTop: themeSpacing(3),
      minHeight: 36,
    },
    resetLinkWrapper: {
      marginBottom: themeSpacing(2),
      marginTop: themeSpacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      fontSize: 14,
    },
  };
});

export const VersionSection = styled.div`
  text-align: center;
  color: hsl(0, 0%, 75%);
  margin-top: 14px;

  &::selection {
    background: #ff5722;
    color: white;
  }
`;
