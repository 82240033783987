import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import styled from 'styled-components';

import { viewPort } from '/styles/global';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 56px;
  grid-column: 1;
`;

export const useUploadItemStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderBottom: '1px solid #c2c2c2',
    padding: `${theme.spacing(2)} 0`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  itemContainer: {
    minHeight: 200,
    maxWidth: viewPort.width,
  },

  actionsContainer: {
    height: 48,
    width: '100%',
  },

  flexContainer: {
    display: 'flex',
    width: '100%',
  },

  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  errorContainer: {
    flex: 1,
    alignItems: 'center',
    tableLayout: 'fixed',
    display: 'table',
    width: '100%',
    overflow: 'hidden',
    transform: 'translate(0, 50%)',
  },

  errorLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  nameInput: {
    minWidth: 300,
  },

  versionPicker: {
    maxWidth: 150,
  },

  control: {
    margin: `${theme.spacing(1)} 0`,
  },

  info: {
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  progress: {
    width: '100%',
  },

  image: {
    width: 200,
    maxHeight: 150,
    height: '100%',
    overflow: 'hidden',
    borderRadius: 5,
  },

  uploadIcon: {
    color: theme.palette.success.main,
  },

  resetIcon: {
    color: theme.palette.warning.main,
  },

  autoGenerateIcon: {
    color: grey[600],
  },

  removeIcon: {
    color: theme.palette.error.main,
  },
}));

export const useBulkApplyStyles = makeStyles({
  container: {
    width: '100%',
  },
  tagsContainer: {
    flex: 1,
  },
  action: {
    marginLeft: 8,
  },
});
